/* eslint-disable no-param-reassign */
import {
  faCheck,
  faBan,
  faUserCheck,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import moment from 'moment'
import store from 'store'
// ToDo remove all API call functions outside of this helper and set a new helper
// we need to make sure this happens so we don't have depth linting
import { getMyUser } from 'data/common/Auth/thunks'
import AuthService from './authService'
import history from './history'
import { NotificationManager } from './NotificationsManager'
import { URLManager } from './URLManager'
import { getUnreadNotifications } from 'data/business/UnreadNotifications/thunks'
import { getShifts } from 'data/business/Shifts/thunks'
import { ClearCurrentlyActiveShiftsReducer } from 'data/business/CurrentlyActiveShifts/actions'
import { getCurrentlyActiveShiftsWithClockedInFetch } from 'data/business/CurrentlyActiveShifts/thunks'
import { ENV_NAME } from 'config/envVariables'

// TTL
export const LOCATION_CHECK_TTL = 5
export const BUSINESS_ENTITY_CHECK_TTL = 1
// 48 hours
export const VACCINATION_MODAL_TTL = 2880
export const NOTIFICATION_CHECK_TTL = 1
export const CURRENTLY_CLOCKED_IN_CHECK_TTL = 5

/**
 *
 * @param {*} param0
 */
export const capitalize = ([first, ...rest]) => {
  const string = `${first.toUpperCase()}${rest.join('').toLowerCase()}`
  return string.replace(/_/g, ' ')
}

export const groupByFromArray = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

/**
 * Colors for the buttons in the Shift Cell
 */
export const actionColors = {
  apply: {
    color: 'success',
    icon: faUserCheck,
  },
  cancel: {
    color: 'danger',
    icon: faBan,
  },
  remove: {
    color: 'danger',
    icon: faBan,
  },
  unblock: {
    color: 'success',
    icon: faBan,
  },
  decline: {
    color: 'danger',
    icon: faBan,
  },
  confirm: {
    color: 'success',
    icon: faCheck,
  },
  details: {
    color: 'success',
    icon: faCheck,
  },
  applicants: {
    color: 'success',
  },
  duplicate: {
    color: 'primary',
    icon: faCheck,
  },
  clock_in: {
    color: 'success',
    icon: faCheck,
  },
  clock_out: {
    color: 'danger',
    icon: faCheck,
  },
  review: {
    color: 'warning',
    icon: faStar,
  },
  duplicated: {
    color: 'primary',
    icon: faStar,
  },
  suspend: {
    color: 'danger',
    icon: faStar,
  },
  activate: {
    color: 'success',
    icon: faStar,
  },
  view: {
    color: 'primary',
    icon: faStar,
  },
  block: {
    color: 'danger',
    icon: faStar,
  },
  accept: {
    color: 'success',
    icon: faStar,
  },
  viewPdf: {
    color: 'primary',
    icon: faStar,
  },
  assign: {
    color: 'success',
    icon: faStar,
  },
  unassign: {
    color: 'danger',
    icon: faStar,
  },
  save: {
    color: 'success',
    icon: faStar,
  },
}

/**
 * Retry loading on chunk
 */

export const retry = (func, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    func()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }
          retry(func, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

/**
 * Sets the title of the filter pill inside the Shifts
 * @param {*} dateState
 */
export const setDateFilterTitle = dateState => {
  const { isActive, dateStart, dateEnd } = dateState
  let activeTitle = 'Date'

  if (isActive) {
    if (dateStart) {
      const fromFormated =
        moment(dateStart).year() === moment(dateEnd).year()
          ? moment(dateStart).format('MMM Do')
          : moment(dateStart).format('Do MMM, YYYY')
      activeTitle = `${fromFormated}`
    }
    if (dateEnd) {
      const toFormated =
        moment(dateEnd).year() === moment(dateStart).year()
          ? moment(dateEnd).month() === moment(dateStart).month()
            ? moment(dateEnd).format('Do')
            : moment(dateEnd).format('MMM Do')
          : moment(dateEnd).format('Do MMM, YYYY')
      activeTitle = `${activeTitle} - ${toFormated}`
    }
  }
  return activeTitle
}

export const capitalizeFirstLetter = string =>
  string.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())

/**
 *
 * @param {*} filtersState
 */
export const setFilterFilterTitle = filtersState => {
  let activeTitle = 'Filter '
  Object.keys(filtersState).forEach(key => {
    const element = filtersState[key]
    const { isActive, name } = element
    if (isActive) activeTitle += `${name} •`
  })
  return activeTitle
}

/**
 *
 * @param {*} filtersState
 */
export const setRegionFilterTitle = locationCookie => {
  let activeTitle = 'My Location'
  if (locationCookie) {
    const locationObject = JSON.parse(locationCookie)
    Object.keys(locationObject).forEach(key => {
      if (key === 'city') {
        activeTitle = locationObject[key]
      }
    })
  }
  return activeTitle
}

/**
 *
 * @param {*} filtersState
 */
export const isFilterActive = filtersState => {
  let filterActive = false
  Object.keys(filtersState).forEach(key => {
    const element = filtersState[key]
    const { isActive } = element
    if (isActive) {
      filterActive = true
    }
  })
  return filterActive
}

/**
 *
 * @param {*} string
 */
export const camelToSnake = string =>
  string
    .replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
    .replace(/^_/, '')

/**
 *
 * @param {*} param0
 * @param {*} location
 */
export function getParams([...args], location) {
  let params = {}
  const array = [...args]
  const currentLocation = location
  const getNow = new URLSearchParams(currentLocation.location.search)
  array.forEach(element => {
    if (typeof element === 'string') {
      params = {
        ...params,
        [element]: getNow.get(`${element}`),
      }
    }
    if (typeof element === 'object') {
      Object.keys(element).forEach(key => {
        params = {
          ...params,
          [key]: element[key],
        }
      })
    }
  })
  return params
}

export const getShiftAllowedParams = () => {
  const params = [
    'search',
    'page',
    'sort_by',
    'date_start',
    'date_end',
    'to_hire',
    'type',
    'auto_accept',
    'favorite',
    'price_end',
    'price_start',
    'lng',
    'lat',
    'distance',
    'distance_in',
    'progress',
    'filled',
    'progress',
    'status',
    'property_id',
    'training_mode',
    'regions[]',
    'gig_types[]',
    'properties[]',
    'account_manager_id',
    'business_ids[]',
    'follow_by_user_ids[]',
    'industry_ids[]',
  ]
  return params
}

/**
 *
 * @param {*} urlSearchParams
 */
export const getShiftsURLParams = urlSearchParams => {
  const params = new URLSearchParams(urlSearchParams)
  const urlAllowedParams = getShiftAllowedParams()
  // const params = {}
  const finalParams = {}
  for (const param of params) {
    // This is workaround for gig params that are array example gigTypes[] , properties[]...
    // this way we are creating our own array , we can't use for example gigTypes[] as key because the key when we send params
    // will be gigTypes[][] and we don't want that
    const key = param[0]
    const value = param[1]
    const allowedToSendToRequest =
      urlAllowedParams.includes(key) && value !== '-1' && value !== ''
    if (allowedToSendToRequest) {
      if (key.includes('[')) {
        const actualKey = [...key].slice(0, key.length - 2).join('')
        finalParams[actualKey] = finalParams[actualKey]
          ? [...finalParams[actualKey], value]
          : [value]
      } else finalParams[key] = value
    }
  }
  return finalParams
  // getNow.forEach((value, key) => {
  //   const keyFormated = camelToSnake(key)
  //   const allowedToSendToRequest =
  //     urlAllowedParams.includes(key) && value !== '-1' && value !== ''
  //   if (allowedToSendToRequest) params[keyFormated] = value
  // })
  // return params
}

export const getShiftParametars = (hasMore = false, currentPage = 0) => {
  const location = localStorage.getItem('location')
  const params = getShiftsURLParams(history.location.search)
  // check location
  if (location) {
    const { longitude, latitude, regionId } = JSON.parse(location)
    if (regionId) {
      params.regions = [regionId]
    } else {
      params.longitude = longitude
      params.latitude = latitude
    }
  }
  if (params.distance) {
    params.distance_in = 'miles'
  }
  if (hasMore) {
    const nextPage = currentPage + 1
    params.page = nextPage
  }
  return params
}

export const getShiftParamsNoPage = () => {
  const location = localStorage.getItem('location')
  const params = getShiftsURLParams(history.location.search)
  // check location
  if (location) {
    const { longitude, latitude, regionId } = JSON.parse(location)
    if (regionId) {
      params.regions = [regionId]
    } else {
      params.longitude = longitude
      params.latitude = latitude
    }
  }
  if (params.distance) {
    params.distance_in = 'miles'
  }
  return params
}

export const fetchShiftsBusiness = (
  specialParams = null,
  getFromUrl = true,
) => {
  let params
  if (getFromUrl) {
    params = getShiftParametars()
  }
  if (specialParams !== null) {
    params = { ...params, ...specialParams }
  }
  store.dispatch(getShifts({ params, preventConcatenate: true }))
}

export const fetchShiftsVirtualizationBusiness = () => {
  const params = getShiftParamsNoPage()
  store.dispatch(getShifts({ params, preventConcatenate: true }))
}

export const getRecommendedParams = urlParams => {
  const params = new URLSearchParams(urlParams)
  const finalParams = {}
  for (const param of params) {
    // This is workaround for gig params that are array example gigTypes[] , properties[]...
    // this way we are creating our own array , we can't use for example gigTypes[] as key because the key when we send params
    // will be gigTypes[][] and we don't want that
    const key = param[0]
    const value = param[1]
    if (key.includes('[')) {
      const actualKey = [...key].slice(0, key.length - 2).join('')
      finalParams[actualKey] = finalParams[actualKey]
        ? [...finalParams[actualKey], value]
        : [value]
    } else finalParams[key] = value
  }
  return finalParams
}

/**
 *
 * @param {*} name
 */
export const removeFromUrl = name => {
  const urlManager = new URLManager()
  urlManager.removeParamAndPush(name)
}

/**
 *
 * @param {*} name
 */
export const copyToClipboard = (name, value) => {
  const textField = document.createElement('textarea')
  textField.innerText = `${history.location.origin}${history.location.pathname}?${name}=${value}`
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  NotificationManager.success('Copied')
}

export const minutesDifferenceBetweenTwoDates = (dt2, dt1) => {
  const diff = dt2 - dt1
  return Math.floor(diff / 1000 / 60)
}

export const setLocationFromUserRegion = () => {
  let locationLocked
  const loggedInUser = AuthService.getLoggedInUser()
  const reduxLocations = store.getState().locations.data
  const reduxLocationsIsFetching = store.getState().locations.isFetching
  const reduxProperties = store.getState().properties.data
  const reduxPropertiesIsFetching = store.getState().properties.isFetching
  // Here we're checking if the user has a region assigned and also we're checking if the locations have finished fetching
  // So we don't get any race conditions while setting the users' location
  if (loggedInUser.regions && reduxLocations && !reduxLocationsIsFetching) {
    const userRegion = loggedInUser.regions[0]
    const region = reduxLocations.filter(
      location => location.id === userRegion.id,
    )
    const currentDate = new Date()
    locationLocked = {
      latitude: region[0].latitude,
      longitude: region[0].longitude,
      city: userRegion.name,
      status: true,
      userPickedLocation: true,
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem('location', JSON.stringify(locationLocked))
    // Here we're checking if the properties have finished fetching
    // So we have data when setting the users' location
  } else if (reduxProperties.length && !reduxPropertiesIsFetching) {
    const region = reduxProperties[0]
    const currentDate = new Date()
    locationLocked = {
      latitude: region.latitude,
      longitude: region.longitude,
      city: region.name,
      status: true,
      userEnabled: true,
      userPickedLocation: true,
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem('location', JSON.stringify(locationLocked))
  }
  return locationLocked
}

export const getUnsavedChangesMessage = pageName => {
  return `You will lose all changes made to the ${pageName}. You are trying to close without saving. Are you sure you want to leave the page?`
}

export const checkAndUpdateUserRegion = () => {
  const loggedInUser = AuthService.getLoggedInUser()
  if (loggedInUser.regions) {
    return setLocationFromUserRegion()
  }
  return store
    .dispatch(getMyUser())
    .then(() => setLocationFromUserRegion())
    .catch(error => {})
}

export async function getLocation() {
  // eslint-disable-next-line no-undef
  const { geolocation } = navigator
  const { initialFetch } = store.getState().properties
  return new Promise((resolve, reject) => {
    const currentDate = new Date()
    if (!geolocation) {
      const locationLocked = {
        latitude: null,
        longitude: null,
        userEnabled: false,
        status: false,
        userPickedLocation: false,
        timestamp: currentDate.getTime(),
      }
      resolve(locationLocked)
    }
    geolocation.getCurrentPosition(
      // This executes when user clicks Allow location
      position => {
        const { coords } = position
        const { latitude, longitude } = coords
        const locationLocked = {
          latitude,
          longitude,
          status: true,
          userEnabled: true,
          userPickedLocation: false,
          timestamp: currentDate.getTime(),
        }
        localStorage.setItem('location', JSON.stringify(locationLocked))
        resolve(locationLocked)
      },
      // This executes when user clicks Decline location
      () => {
        resolve(checkAndUpdateUserRegion())
      },
    )
  })
  // dispatch some kind of indicator that will trigger re-render in Regions.jsx
  // maybe a flag that will represent if the user allowed or blocked the option
}

/**
 * Checks if the location should be updated
 */
export const checkIfLocationShouldBeUpdated = () => {
  const location = localStorage.getItem('location')
  if (location && !location.userPickedLocation) {
    const { timestamp } = JSON.parse(location)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference > LOCATION_CHECK_TTL
  }

  return true
}

/**
 * Checks if the user location should be updated
 */
export const localStorageTimerCheck = (localStorageItem, timerMinutes) => {
  const item = localStorage.getItem(localStorageItem)
  if (item) {
    const { timestamp } = JSON.parse(item)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference > timerMinutes
  }
  return true
}

/**
 * Checks if business entity should be updated
 */
export const shouldBusinessEntityBeUpdated = () => {
  const businessEntityLastUpdate = localStorage.getItem(
    'businessEntityLastUpdate',
  )
  if (businessEntityLastUpdate) {
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      businessEntityLastUpdate,
    )
    return minuteDifference > BUSINESS_ENTITY_CHECK_TTL
  }

  return true
}

/**
 * Checks if business entity should be updated
 */
export const shouldVaccinationReminderModalPopUp = () => {
  const latestVaccinationInfo = AuthService.getLatestVaccinationInfo()

  if (latestVaccinationInfo) {
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      latestVaccinationInfo,
    )
    return minuteDifference > VACCINATION_MODAL_TTL
  }

  return true
}

export const checkIfNotificationShouldBeUpdated = () => {
  const notification = localStorage.getItem('notification')
  if (notification) {
    const { timestamp } = JSON.parse(notification)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference >= NOTIFICATION_CHECK_TTL
  }
  const currentDate = new Date()
  const notificationObject = {
    timestamp: currentDate.getTime(),
  }
  localStorage.setItem('notification', JSON.stringify(notificationObject))
  return true
}

/**
 * Checks if the business users clocked in upshifteres should be checked for any news
 * if so, then makes 2 api calls
 * 1. get gigs that are active
 * 2. gets all the active punch-cards for that shift
 */
export const checkIfCurrentlyClockedInShouldBeUpdated = () => {
  const activeAndCurrentlyClockedIn = localStorage.getItem(
    'activeAndCurrentlyClockedIn',
  )
  if (activeAndCurrentlyClockedIn) {
    const { timestamp } = JSON.parse(activeAndCurrentlyClockedIn)
    const now = new Date()
    const minuteDifference = minutesDifferenceBetweenTwoDates(
      now.getTime(),
      timestamp,
    )
    return minuteDifference >= parseInt(CURRENTLY_CLOCKED_IN_CHECK_TTL, 10)
  }
  const currentDate = new Date()
  const notificationObject = {
    timestamp: currentDate.getTime(),
  }
  localStorage.setItem(
    'activeAndCurrentlyClockedIn',
    JSON.stringify(notificationObject),
  )
  return true
}

export const updateUnreadNotifications = shouldBypassUpdate => {
  const shouldUpdate = checkIfNotificationShouldBeUpdated()
  if (shouldUpdate || shouldBypassUpdate) {
    const currentDate = new Date()
    const notificationObject = {
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem('notification', JSON.stringify(notificationObject))

    store.dispatch(getUnreadNotifications())
  }
}

/**
 * Updating the shift screen
 */
export const updateActiveShiftsAndCurrentlyClockedInUpshifters = initialFetch => {
  const businessEntity = AuthService.getBusinessEntity()
  const params = {
    progress: 1,
  }
  const isActiveLink = history.location.pathname.includes('/active_shift')
  const shouldUpdate = checkIfCurrentlyClockedInShouldBeUpdated()
  if ((shouldUpdate || !initialFetch) && !isActiveLink) {
    const currentDate = new Date()
    const timestampObject = {
      timestamp: currentDate.getTime(),
    }
    localStorage.setItem(
      'activeAndCurrentlyClockedIn',
      JSON.stringify(timestampObject),
    )
    store.dispatch(ClearCurrentlyActiveShiftsReducer())
    store.dispatch(
      getCurrentlyActiveShiftsWithClockedInFetch({
        ...params,
        business_id: businessEntity.id,
      }),
    )
  }
}

/**
 * Creates and returns a list of all years
 * @param {Number} minOffset
 * @param {Number} maxOffset
 */
export const getYearRange = (minOffset = 0, maxOffset = 10) => {
  const thisYear = new Date().getFullYear()
  const options = []
  for (let i = minOffset; i <= maxOffset; i += 1) {
    const year = thisYear + i
    options.push({ label: year, value: year })
  }
  return options
}

/**
 * Creates and returns a list of all dates in a month
 * @param {Number} minOffset
 * @param {Number} maxOffset
 */
export const getMonthRange = (minOffset = 0, maxOffset = 10) => {
  const options = []
  for (let i = 1; i <= 12; i += 1) {
    let month
    if (i < 10) {
      month = `0${i}`
    } else {
      month = i
    }
    options.push({ label: month, value: month })
  }
  return options
}

export const updateArray = (data, payload) => {
  const updatedArray = data.map(item => {
    if (item.id !== payload.id) {
      return item
    }
    return payload
  })
  return updatedArray
}

export const isObjectEmpty = arg => Object.entries(arg).length === 0

export const isMobile = () => window.innerWidth <= 576
export const findIfUpshifterHasCanceledNextWorkingDay = (
  multidayDays,
  canceledDays,
) => {
  const nextWorkingDay = multidayDays.find(day => {
    const today = new Date()
    const nextDay = new Date(day.date_start)
    if (nextDay > today && day.working_day) {
      // first date is in future, or it is today
      return day
    }
    return null
  })
  if (nextWorkingDay && Array.isArray(canceledDays)) {
    const nextCanceledDay = canceledDays.find(day => {
      if (nextWorkingDay.id === day.day_id) return true
      return false
    })
    return nextCanceledDay
  }
  return false
}

export const isTablet = () => window.innerWidth <= 768

/**
 * Function that takes the user back to the previously navigated path. If it doesn't exist, the user is taken to the fallback path provided as param
 * @param {String} fallbackPath
 */
export const goBackHandler = fallbackPath => {
  const prevPage = history.location.href
  if (!document.referrer && history.length < 3) {
    history.push(fallbackPath)
  } else {
    history.go(-1)
    setTimeout(() => {
      if (history.location.href === prevPage) {
        history.push(fallbackPath)
      }
    }, 500)
  }
}

/**
 * Copies a text to clipboard
 * @param textToCopy The text to copy to clipboard
 * @param notificationMessage Notification message to display on successful copy
 */
export const copyTextToClipboard = (
  textToCopy,
  notificationMessage = 'Copied to clipboard',
) => {
  const textField = document.createElement('textarea')
  textField.innerText = textToCopy
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  NotificationManager.success(notificationMessage)
}

export const checkIfShiftIsWithin24hours = shift => {
  const currentBrowserTimezone = moment.tz.guess()
  const currentBrowserTime = moment().format()
  const timeStart = shift.time_start
  const shiftTimezone = shift.property_timezone
  const timeStartWithTimeZone = moment(timeStart).tz(shiftTimezone)
  const timeStartInShiftTimeZone = moment(timeStartWithTimeZone).tz(
    currentBrowserTimezone,
  )
  const duration = moment.duration(
    timeStartInShiftTimeZone.diff(currentBrowserTime),
  )
  const hoursDifference = duration.asHours()
  return hoursDifference <= 24
}

export const trackingEnvName = ENV_NAME

export const is2XS = () => window.innerWidth < 230

export const isXS = () => window.innerWidth >= 230 && window.innerWidth < 576

export const isSM = () => window.innerWidth >= 576 && window.innerWidth < 768

export const isMD = () => window.innerWidth >= 768 && window.innerWidth < 992

export const isLG = () => window.innerWidth >= 992 && window.innerWidth < 1024

export const isXL = () => window.innerWidth >= 1024 && window.innerWidth < 1300

export const is2XL = () => window.innerWidth >= 1300 && window.innerWidth < 1500

export const is3XL = () => window.innerWidth >= 1500 && window.innerWidth < 1700

export const is4XL = () => window.innerWidth >= 1700 && window.innerWidth < 1900

export const is5XL = () => window.innerWidth >= 1900
