import { IPayload } from 'models/Global'
import { IGetGroupMembersParams, IGroupMember } from 'models/GroupMember'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class GroupMembersApi {
  constructor(private axios: AxiosService) {}

  getGroupMembers = async (params?: IGetGroupMembersParams) => {
    const response = await this.axios.get<IPayload<IGroupMember[]>>(
      Endpoints.getGroupMembers,
      params,
    )
    return response
  }
}
