import { AuthApi } from './Auth'
import { AxiosService } from 'network/config/config'
import { NotesApi } from './Note'
import { BusinessEntitiesApi } from './BusinessEntity'
import { InvoicesApi } from './Invoice'
import { ReportsApi } from './Report'
import { AttachmentsApi } from './Attachment'
import { DocumentsApi } from './Document'
import { AcknowledgedDocumentsApi } from './AcknowledgedDocument'
import { UsersApi } from './User'
import { NetOptionsApi } from './NetOption'
import { StateApi } from './State'
import { IndustriesApi } from './Industry'
import { PunchCardsApi } from './PunchCard'
import { RegionApi } from './Region'
import { FavoritesApi } from './Favorite'
import { TimesheetsApi } from './Timesheet'
import { ShiftsApi } from './Shift'
import { MinimumWagesApi } from './MinimumWage'
import { SickLeaveTemplatesApi } from './SickLeaveTemplate'
import { InvoiceGroupsApi } from './InvoiceGroup'
import { CreditsApi } from './Credit'
import { PropertiesApi } from './Property'
import { InvoiceExpenseTypesApi } from './InvoiceExpenseType'
import { ApplicationsApi } from './Applications'
import { GigDaysApi } from './GigDay'
import { CreditCardsApi } from './CreditCard'
import { GigTypesApi } from './GigType'
import { AutoAcceptCertificatesApi } from './AutoAcceptCertificate'
import { ShiftAutoAcceptCertificatesApi } from './ShiftAutoAcceptCertificate'
import { PropertyTypesApi } from './PropertyType'
import { ReliabilityRatingsApi } from './ReliabilityRating'
import { ReviewsApi } from './Review'
import { TemplatesApi } from './Template'
import { BlocksApi } from './Block'
import { UserCertificatesApi } from './UserCertificate'
import { ExperiencesApi } from './Experience'
import { PasswordApi } from './Password'
import { NotificationPreferencesApi } from './NotificationPreference'
import { NotificationsApi } from './Notification'
import { ScheduledUpshiftersApi } from './ScheduledUpshifter'
import { TemplateAutoAcceptCertificatesApi } from './TemplateAutoAcceptCertificate'
import { ShortLinksApi } from './ShortLink'
import { ActivityLogsApi } from './ActivityLog'
import { BonusesApi } from 'network/Bonuses'
import { VaccinationTypesApi } from 'network/VaccinationType'
import { VaccinationFormsApi } from 'network/VaccinationForm'
import { MagicLinkApi } from 'network/MagicLink'
import { PendingVerificationPunchCardsApi } from 'network/PendingVerificationPunchCard'
import { CalendarsApi } from 'network/Calendar'
import { ShiftIdentifierPolicyApi } from 'network/ShiftIdentifierPolicy'
import { GroupInvitesApi } from 'network/GroupInvite'
import { WebSocketDataApi } from 'network/WebSocketData'
import { SuggestedGroupNameApi } from 'network/SuggestedGroupName'
import { GroupApplicationsApi } from 'network/GroupApplication'
import { RequestApplicationsApi } from 'network/RequestApplication'
import { AchAccountsApi } from 'network/AchAccount'
import { BlockPropertiesApi } from 'network/BlockProperty'
import { GroupMembersApi } from 'network/GroupMember'

class Api {
  axios = new AxiosService()

  Auth = new AuthApi(this.axios)

  Password = new PasswordApi(this.axios)

  Shifts = new ShiftsApi(this.axios)

  Notes = new NotesApi(this.axios)

  BusinessEntities = new BusinessEntitiesApi(this.axios)

  Invoices = new InvoicesApi(this.axios)

  PunchCards = new PunchCardsApi(this.axios)

  Reports = new ReportsApi(this.axios)

  Properties = new PropertiesApi(this.axios)

  Attachments = new AttachmentsApi(this.axios)

  Documents = new DocumentsApi(this.axios)

  AcknowledgedDocuments = new AcknowledgedDocumentsApi(this.axios)

  Users = new UsersApi(this.axios)

  NetOptions = new NetOptionsApi(this.axios)

  States = new StateApi(this.axios)

  Industries = new IndustriesApi(this.axios)

  Regions = new RegionApi(this.axios)

  Favorites = new FavoritesApi(this.axios)

  MinimumWage = new MinimumWagesApi(this.axios)

  Timesheets = new TimesheetsApi(this.axios)

  SickLeaveTemplates = new SickLeaveTemplatesApi(this.axios)

  InvoiceGroups = new InvoiceGroupsApi(this.axios)

  Credits = new CreditsApi(this.axios)

  InvoiceExpenseTypes = new InvoiceExpenseTypesApi(this.axios)

  Applications = new ApplicationsApi(this.axios)

  GigDays = new GigDaysApi(this.axios)

  CreditCards = new CreditCardsApi(this.axios)

  GigTypes = new GigTypesApi(this.axios)

  AutoAcceptCertificates = new AutoAcceptCertificatesApi(this.axios)

  ShiftAutoAcceptCertificates = new ShiftAutoAcceptCertificatesApi(this.axios)

  PropertyTypes = new PropertyTypesApi(this.axios)

  ReliabilityRatings = new ReliabilityRatingsApi(this.axios)

  Reviews = new ReviewsApi(this.axios)

  Templates = new TemplatesApi(this.axios)

  Blocks = new BlocksApi(this.axios)

  UserCertificates = new UserCertificatesApi(this.axios)

  Experiences = new ExperiencesApi(this.axios)

  NotificationPreferences = new NotificationPreferencesApi(this.axios)

  Notifications = new NotificationsApi(this.axios)

  ScheduledUpshifters = new ScheduledUpshiftersApi(this.axios)

  TemplateAutoAcceptCertificates = new TemplateAutoAcceptCertificatesApi(
    this.axios,
  )

  ShortLinks = new ShortLinksApi(this.axios)

  ActivityLogs = new ActivityLogsApi(this.axios)

  Bonuses = new BonusesApi(this.axios)

  VaccinationTypes = new VaccinationTypesApi(this.axios)

  VaccinationForms = new VaccinationFormsApi(this.axios)

  MagicLink = new MagicLinkApi(this.axios)

  PendingVerificationPunchCards = new PendingVerificationPunchCardsApi(
    this.axios,
  )

  Calendars = new CalendarsApi(this.axios)

  ShiftIdentifierPolicies = new ShiftIdentifierPolicyApi(this.axios)

  GroupInvites = new GroupInvitesApi(this.axios)

  GroupMembers = new GroupMembersApi(this.axios)

  WebSocketData = new WebSocketDataApi(this.axios)

  SuggestedGroupNames = new SuggestedGroupNameApi(this.axios)

  GroupApplications = new GroupApplicationsApi(this.axios)

  RequestApplications = new RequestApplicationsApi(this.axios)

  AchAccounts = new AchAccountsApi(this.axios)

  BlockProperties = new BlockPropertiesApi(this.axios)
}

export const API = new Api()
