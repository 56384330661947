import {
  IShift,
  IShiftReducer,
  SHIFT_TYPE,
  IShiftReducerProps,
} from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  BusinessShiftLoading,
  GetBusinessOpenShift,
  CleanBusinessOpenShift,
  GetBusinessShiftError,
  ResetBusinessActiveShift,
  BusinessShiftUpdateApplicants,
  BusinessShiftDecrementConfirmedIncrementPending,
  BusinessShiftDecrementPendingIncrementConfirmed,
  BusinessShiftDecrementAccepted,
  BusinessShiftDecrementPending,
} from './actions'
import { FEATURE_TOGGLE_MULTIDAY_REVAMP } from 'config/featureToggles'

const initialState: IShiftReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {
    shift: {},
    openedShift: {},
    loading: false,
    btnShiftLoading: false,
    isActiveShift: false,
    isShiftScreenActive: false,
  } as IShiftReducerProps,
}

export const shiftReducer = (
  state = initialState,
  action: Action,
): IShiftReducer => {
  if (isType(action, BusinessShiftLoading)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
      data: { ...state.data },
    }
  }
  if (isType(action, GetBusinessOpenShift)) {
    const { payload } = action
    return {
      ...state,
      error: undefined,
      data: {
        ...state.data,
        openedShift: payload,
        btnShiftLoading: false,
        isActiveShift: true,
      },
    }
  }
  if (isType(action, ResetBusinessActiveShift)) {
    return {
      ...state,
      data: {
        ...state.data,
        isActiveShift: true,
        isShiftScreenActive: false,
      },
    }
  }
  if (isType(action, CleanBusinessOpenShift)) {
    return {
      ...state,
      data: { ...state.data, openedShift: {} as IShift },
    }
  }
  if (isType(action, GetBusinessShiftError)) {
    return {
      ...state,
      error: action.payload,
      isFetching: false,
      data: { ...state.data },
    }
  }

  if (isType(action, BusinessShiftUpdateApplicants)) {
    const { data } = state
    const newOpenShift = { ...data.openedShift }
    newOpenShift.applicants = action.payload.applicants
    return {
      ...state,
      data: { ...state.data, openedShift: newOpenShift },
    }
  }

  if (isType(action, BusinessShiftDecrementConfirmedIncrementPending)) {
    const { data } = state
    const newOpenShift = { ...data.openedShift }
    // This flag only affects the consolidated view. In the consolidated view, we fetch accepted gig days to filter applications per gig day and we need this to update the counters properly.
    // This is not the case in the shift details view where we display the total number of applications per shift.
    const shouldUpdateAcceptedApplicationsCounter =
      FEATURE_TOGGLE_MULTIDAY_REVAMP &&
      Number(newOpenShift.type) === SHIFT_TYPE.MULTIDAY
    newOpenShift.applicants.pending_count += 1
    newOpenShift.applicants.accepted_count -= 1
    if (
      shouldUpdateAcceptedApplicationsCounter &&
      typeof newOpenShift.accepted_applications_count !== 'undefined'
    ) {
      newOpenShift.accepted_applications_count -= 1
    }
    if (action.payload.confirmed) {
      newOpenShift.applicants.confirmed_count -= 1
    }
    return {
      ...state,
      data: { ...state.data, openedShift: newOpenShift },
    }
  }
  if (isType(action, BusinessShiftDecrementPendingIncrementConfirmed)) {
    const { data } = state
    const newOpenShift = { ...data.openedShift }
    // This flag only affects the consolidated view. In the consolidated view, we fetch accepted gig days to filter applications per gig day and we need this to update the counters properly.
    // This is not the case in the shift details view where we display the total number of applications per shift.
    const shouldUpdateAcceptedApplicationsCounter =
      FEATURE_TOGGLE_MULTIDAY_REVAMP &&
      Number(newOpenShift.type) === SHIFT_TYPE.MULTIDAY
    newOpenShift.applicants.pending_count -= 1
    newOpenShift.applicants.accepted_count += 1
    if (
      shouldUpdateAcceptedApplicationsCounter &&
      typeof newOpenShift.accepted_applications_count !== 'undefined'
    ) {
      newOpenShift.accepted_applications_count += 1
    }
    return {
      ...state,
      data: { ...state.data, openedShift: newOpenShift },
    }
  }
  if (isType(action, BusinessShiftDecrementAccepted)) {
    const { data } = state
    const newOpenShift = { ...data.openedShift }
    newOpenShift.applicants.accepted_count -= 1
    if (action.payload.confirmed) {
      newOpenShift.applicants.confirmed_count -= 1
    }
    return {
      ...state,
      data: { ...state.data, openedShift: newOpenShift },
    }
  }
  if (isType(action, BusinessShiftDecrementPending)) {
    const { data } = state
    const newOpenShift = { ...data.openedShift }
    newOpenShift.applicants.pending_count -= 1
    return {
      ...state,
      data: { ...state.data, openedShift: newOpenShift },
    }
  }

  return state
}
