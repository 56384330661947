import { action, payload } from 'ts-action'
import { INotification } from 'models/Notification'
import { IError, IPayload } from 'models/Global'

export const NotificationsFetching = action(
  'NotificationsFetching',
  payload<boolean>(),
)

export const GetNotifications = action(
  'GetNotifications',
  payload<IPayload<INotification[]>>(),
)

export const GetNotificationsWithoutConcat = action(
  'GetNotificationsWithoutConcat',
  payload<IPayload<INotification[]>>(),
)

export const GetNotificationsErrors = action(
  'GetNotificationsErrors',
  payload<IError>(),
)

export const UpdateNotification = action(
  'UpdateNotification',
  payload<IPayload<INotification>>(),
)

export const UpdateAllNotificationsStatus = action(
  'UpdateAllNotificationsStatus',
)

export const DeleteNotification = action(
  'DeleteNotification',
  payload<number>(),
)

export const ClearNotifications = action('ClearNotifications')
