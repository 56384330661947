import { updateArray } from 'helpers/helperFunctions'
import { INotificationsReducer, NOTIFICATION_STATUS } from 'models/Notification'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetNotifications,
  NotificationsFetching,
  GetNotificationsErrors,
  ClearNotifications,
  UpdateNotification,
  UpdateAllNotificationsStatus,
  DeleteNotification,
  GetNotificationsWithoutConcat,
} from './actions'

const initialState: INotificationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const notificationsReducer = (
  state = initialState,
  action: Action,
): INotificationsReducer => {
  if (isType(action, GetNotifications)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [...state.data, ...payload.data],
    }
  }

  if (isType(action, GetNotificationsWithoutConcat)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpdateNotification)) {
    const { payload } = action
    return {
      ...state,
      data: updateArray(state.data, payload.data),
    }
  }

  if (isType(action, UpdateAllNotificationsStatus)) {
    const { data } = state

    const updatedNotifications = data.map(notification => ({
      ...notification,
      status: NOTIFICATION_STATUS.READ,
    }))

    return {
      ...state,
      data: updatedNotifications,
    }
  }

  if (isType(action, DeleteNotification)) {
    const { payload: id } = action
    const { data } = state

    const filteredNotifications = data.filter(
      notification => notification.id !== id,
    )

    return {
      ...state,
      data: filteredNotifications,
    }
  }

  if (isType(action, GetNotificationsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, NotificationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ClearNotifications)) {
    return initialState
  }

  return state
}
