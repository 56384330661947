// Object of endpoints that are used accross the app
export const Endpoints = {
  // Auth
  login: '/auth/login',
  magic_login: '/auth/magic_login',
  logout: '/auth/logout',
  me: '/auth/me',
  validateToken: `/auth/validate`,
  refreshToken: `/auth/refresh`,

  // Password
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
  changePassword: '/password/change',

  // Shifts
  getShifts: '/gigs',
  getShift: (id: number) => `/gigs/${id}`,
  postShift: '/gigs',
  putShift: (id: number) => `/gigs/${id}`,
  deleteShift: (id: number) => `/gigs/${id}`,

  // PunchCards
  getPunchCards: '/punch_cards',
  getPunchCard: (id: number) => `/punch_cards/${id}`,
  postPunchCard: `/punch_cards`,
  putPunchCard: (id: number) => `punch_cards/${id}`,
  deletePunchCard: (id: number) => `punch_cards/${id}`,

  // Users
  getUsers: '/users',
  getUser: (id: number) => `/users/${id}`,
  postUser: '/users',
  putUser: (id: number) => `/users/${id}`,
  deleteUser: (id: number) => `/users/${id}`,
  getUserSickPayDayHourStats: (id: number) => `/users/${id}/sick_pay_day_hours`,
  updateUserAvatar: (id: number) => `/users/${id}/avatar`,
  checkUserPhoneType: `/users/phone_type`,
  getAddressInformation: (id: number) => `/users/${id}/address_information`,
  updateAddressInformation: (id: number) => `users/${id}/address_information`,
  getDirectDepositInformation: (id: number) =>
    `/users/${id}/direct_deposit_information`,
  updateDirectDepositInformation: (id: number) =>
    `users/${id}/direct_deposit_information`,

  // Properties

  getProperties: '/properties',
  getProperty: (id: number) => `/properties/${id}`,
  getPropertyAddressInfo: '/properties/address_info',
  postProperty: '/properties',
  putProperty: (id: number) => `/properties/${id}`,
  deleteProperty: (id: number) => `/properties/${id}`,

  // Business Entities
  getBusinessEntities: '/businesses',
  getBusinessEntity: (id: number) => `/businesses/${id}`,
  postBusinessEntity: '/businesses',
  updateBusinessEntity: (id: number) => `/businesses/${id}`,
  deleteBusinessEntity: (id: number) => `/businesses/${id}`,

  // Regions
  getRegions: '/regions',
  getRegion: (id: number) => `/regions/${id}`,
  postRegion: '/regions',
  putRegion: (id: number) => `/regions/${id}`,
  deleteRegion: (id: number) => `/regions/${id}`,

  // Industries
  getIndustries: '/industries',
  getIndustry: (id: number) => `/industries/${id}`,
  postIndustry: '/industries',
  putIndustry: (id: number) => `/industries/${id}`,
  deleteIndustry: (id: number) => `/industries/${id}`,

  // States
  getStates: '/states',
  getState: (id: number) => `/states/${id}`,
  postState: '/states',
  putState: (id: number) => `/states/${id}`,
  deleteState: (id: number) => `/states/${id}`,

  // Countries
  getCountries: '/countries',
  getCountry: (id: number) => `/countries/${id}`,
  putCountry: (id: number) => `/countries/${id}`,
  postCountry: '/countries',
  deleteCountry: (id: number) => `/countries/${id}`,

  // Minimum Wages
  getMinimumWages: '/minimum_wages',
  getMinimumWage: (id: number) => `/minimum_wages/${id}`,
  postMinimumWage: '/minimum_wages',
  putMinimumWage: (id: number) => `/minimum_wages/${id}`,
  deleteMinimumWage: (id: number) => `/minimum_wages/${id}`,

  // Net Options
  getNetOptions: '/net_options',
  getNetOption: (id: number) => `/net_options/${id}`,
  postNetOption: '/net_options',
  putNetOption: (id: number) => `/net_options/${id}`,
  deleteNetOption: (id: number) => `/net_options/${id}`,

  // Notes
  getNotes: '/notes',
  getNote: (id: number) => `/notes/${id}`,
  postNote: '/notes',
  putNote: (id: number) => `/notes/${id}`,
  deleteNote: (id: number) => `notes/${id}`,

  // Timesheets
  getTimesheets: '/timesheets',
  getTimesheet: (id: number) => `/timesheets/${id}`,
  postTimesheet: '/timesheets',
  putTimesheet: (id: number) => `/timesheets/${id}`,
  deleteTimesheet: (id: number) => `/timesheets/${id}`,
  getTimesheetCSVReport: (id: number) => `/timesheets/generate_csv/${id}`,

  // Invoices
  getInvoices: '/invoices',
  getInvoice: (id: number) => `/invoices/${id}`,
  postInvoice: '/invoices',
  payInvoice: (id: number) => `/invoices/${id}/pay`,
  putInvoice: (id: number) => `/invoices/${id}`,
  deleteInvoice: (id: number) => `/invoices/${id}`,
  processAllInvoices: '/invoices/mark_all_processed',
  getInvoicesStatement: '/invoices/statement',

  // ExpenseTypes
  getExpenseTypes: '/expense_types',
  getExpenseType: (id: number) => `/expense_types/${id}`,
  postExpenseType: '/expense_types',
  putExpenseType: (id: number) => `/expense_types/${id}`,
  deleteExpenseType: (id: number) => `/expense_types/${id}`,

  // InvoiceExpenseTypes
  getInvoiceExpenseTypes: '/invoice_expense_types',
  getInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,
  postInvoiceExpenseType: '/invoice_expense_types',
  putInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,
  deleteInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,

  // Credits
  getCredits: '/credits',
  getCredit: (id: number) => `/credits/${id}`,
  postCredit: '/credits',
  putCredit: (id: number) => `/credits/${id}`,
  deleteCredit: (id: number) => `/credits/${id}`,

  // Invoice Groups
  getInvoiceGroups: '/invoice_groups',
  getInvoiceGroup: (id: number) => `/invoice_groups/${id}`,
  postInvoiceGroup: '/invoice_groups',
  putInvoiceGroup: (id: number) => `/invoice_groups/${id}`,
  deleteInvoiceGroup: (id: number) => `/invoice_groups/${id}`,

  // Reports
  getInvoiceZipReports: '/reports/invoices',
  getTimesheetsReport: '/reports/timesheets',
  getStatsByDateReport: '/reports/stats_by_date',
  getReturnRateReport: '/reports/return_rate',

  // Attachments
  getAttachments: '/attachments',
  getAttachment: (id: number) => `/attachments/${id}`,
  postAttachment: '/attachments',
  putAttachment: (id: number) => `/attachments/${id}`,
  deleteAttachment: (id: number) => `/attachments/${id}`,

  // Attachment Titles
  getAttachmentTitles: '/attachment_titles',
  getAttachmentTitle: (id: number) => `/attachment_titles/${id}`,
  postAttachmentTitle: '/attachment_titles',
  putAttachmentTitle: (id: number) => `/attachment_titles/${id}`,
  deleteAttachmentTitle: (id: number) => `/attachment_titles/${id}`,

  // Documents
  getDocuments: '/documents',
  getDocument: (id: number) => `/documents/${id}`,
  postDocument: '/documents',
  putDocument: (id: number) => `/documents/${id}`,
  deleteDocument: (id: number) => `/documents/${id}`,

  // Acknowledged Documents
  getAcknowledgedDocuments: '/acknowledged_documents',
  getAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,
  getMultipleAcknowledgedDocuments: '/reports/download_acknowledged_docs',
  postAcknowledgedDocument: '/acknowledged_documents',
  putAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,
  deleteAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,

  // Sick Paid Leave Templates
  getSickLeaveTemplates: '/paid_sick_leave_templates',
  getSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,
  postSickLeaveTemplate: '/paid_sick_leave_templates',
  putSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,
  deleteSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,

  // Favorites
  getFavorites: '/favorites',
  getFavorite: (id: number) => `/favorites/${id}`,
  postFavorite: '/favorites',
  putFavorite: (id: number) => `/favorites/${id}`,
  deleteFavorite: (id: number) => `/favorites/${id}`,

  // Applications
  getApplications: '/applications',
  getApplication: (id: number) => `/applications/${id}`,
  postApplication: '/applications',
  putApplication: (id: number) => `/applications/${id}`,
  deleteApplication: (id: number) => `/applications/${id}`,
  getConfirmedApplicationsPdf: '/applications/confirmed',

  // Gig days
  getGigDays: '/gig_days',

  // GigTypes
  getGigTypes: '/gig_types',
  getGigType: (id: number) => `/gig_types/${id}`,
  postGigType: `/gig_types`,
  putGigType: (id: number) => `/gig_types/${id}`,
  deleteGigType: (id: number) => `/gig_types/${id}`,

  // CreditCards
  getCreditCards: '/credit_cards',
  getCreditCard: (id: number) => `/credit_cards/${id}`,
  postCreditCard: `/credit_cards`,
  putCreditCard: (id: number) => `/credit_cards/${id}`,
  deleteCreditCard: (id: number) => `/credit_cards/${id}`,

  // AutoAcceptCertificates
  getAutoAcceptCertificates: '/auto_accept_certificates',
  getAutoAcceptCertificate: (id: number) => `/auto_accept_certificates/${id}`,
  postAutoAcceptCertificate: `/auto_accept_certificates`,
  putAutoAcceptCertificate: (id: number) => `/auto_accept_certificates/${id}`,
  deleteAutoAcceptCertificate: (id: number) =>
    `/auto_accept_certificates/${id}`,

  // TemplateAutoAcceptCertificates
  getTemplateAutoAcceptCertificates: '/template_auto_accept_certificates',
  getTemplateAutoAcceptCertificate: (id: number) =>
    `/template_auto_accept_certificates/${id}`,
  postTemplateAutoAcceptCertificate: `/template_auto_accept_certificates`,
  putTemplateAutoAcceptCertificate: (id: number) =>
    `/template_auto_accept_certificates/${id}`,
  deleteTemplateAutoAcceptCertificate: (id: number) =>
    `/template_auto_accept_certificates/${id}`,

  // ShiftAutoAcceptCertificates
  getShiftAutoAcceptCertificates: '/gig_auto_accept_certificates',
  getShiftAutoAcceptCertificate: (id: number) =>
    `/gig_auto_accept_certificates/${id}`,
  postShiftAutoAcceptCertificate: `/gig_auto_accept_certificates`,
  putShiftAutoAcceptCertificate: (id: number) =>
    `/gig_auto_accept_certificates/${id}`,
  deleteShiftAutoAcceptCertificate: (id: number) =>
    `/gig_auto_accept_certificates/${id}`,

  // UserCertificates
  getUserCertificates: '/user_certificates',
  getUserCertificate: (id: number) => `/user_certificates/${id}`,
  postUserCertificate: `/user_certificates`,
  putUserCertificate: (id: number) => `/user_certificates/${id}`,
  deleteUserCertificate: (id: number) => `/user_certificates/${id}`,

  // PropertyTypes
  getPropertyTypes: '/property_types',
  getPropertyType: (id: number) => `/property_types/${id}`,
  postPropertyType: `/property_types`,
  putPropertyType: (id: number) => `/property_types/${id}`,
  deletePropertyType: (id: number) => `/property_types/${id}`,

  // ReliabilityRatings
  getReliabilityRatings: '/reliability_ratings',
  getReliabilityRating: (id: number) => `/reliability_ratings/${id}`,
  postReliabilityRating: `/reliability_ratings`,
  putReliabilityRating: (id: number) => `/reliability_ratings/${id}`,
  deleteReliabilityRating: (id: number) => `/reliability_ratings/${id}`,

  // Reviews
  getReviews: '/reviews',
  getReview: (id: number) => `/reviews/${id}`,
  postReview: `/reviews`,
  putReview: (id: number) => `/reviews/${id}`,
  deleteReview: (id: number) => `/reviews/${id}`,

  // Templates
  getTemplates: '/templates',
  getTemplate: (id: number) => `/templates/${id}`,
  postTemplate: `/templates`,
  putTemplate: (id: number) => `/templates/${id}`,
  deleteTemplate: (id: number) => `/templates/${id}`,

  // Blocks
  getBlocks: '/blocks',
  getBlock: (id: number) => `/blocks/${id}`,
  postBlock: `/blocks`,
  putBlock: (id: number) => `/blocks/${id}`,
  deleteBlock: (id: number) => `/blocks/${id}`,

  // Experiences
  getExperiences: '/experiences',
  getExperience: (id: number) => `/experiences/${id}`,
  postExperience: `/experiences`,
  putExperience: (id: number) => `/experiences/${id}`,
  deleteExperience: (id: number) => `/experiences/${id}`,

  // GigTypes Experiences
  getGigTypeExperiences: '/experiences/gig_type_categories',

  // NotificationPreferences
  getNotificationPreferences: '/notification_preferences',
  getNotificationPreference: (id: number) => `/notification_preferences/${id}`,
  postNotificationPreference: `/notification_preferences`,
  putNotificationPreference: (id: number) => `/notification_preferences/${id}`,
  deleteNotificationPreference: (id: number) =>
    `/notification_preferences/${id}`,

  // Notifications
  getNotifications: '/notifications',
  getNotification: (id: number) => `/notifications/${id}`,
  postNotification: `/notifications`,
  putNotification: (id: number) => `/notifications/${id}`,
  deleteNotification: (id: number) => `/notifications/${id}`,
  readNotifications: '/notifications/read',

  // ScheduledUpshifters
  getScheduledUpshifters: '/scheduled_upshifters',
  getScheduledUpshifter: (id: number) => `/scheduled_upshifters/${id}`,
  postScheduledUpshifter: `/scheduled_upshifters`,
  putScheduledUpshifter: (id: number) => `/scheduled_upshifters/${id}`,
  deleteScheduledUpshifter: (id: number) => `/scheduled_upshifters/${id}`,

  // ShortLinks
  postShortLink: '/short_links',

  // FeaturedShiftsCampaigns
  getFeaturedShiftsCampaigns: '/featured_gigs_campaigns',
  getFeaturedShiftsCampaign: (id: number) => `/featured_gigs_campaigns/${id}`,
  postFeaturedShiftsCampaign: `/featured_gigs_campaigns`,
  putFeaturedShiftsCampaign: (id: number) => `/featured_gigs_campaigns/${id}`,
  deleteFeaturedShiftsCampaign: (id: number) =>
    `/featured_gigs_campaigns/${id}`,

  // ActivityLog
  getActivityLogs: `/activity_logs`,
  getActivityLog: (id: number) => `/activity_logs/${id}`,

  // Bonuses
  getBonuses: '/bonuses',
  getBonus: (id: number) => `/bonuses/${id}`,
  postBonus: `/bonuses`,
  putBonus: (id: number) => `/bonuses/${id}`,
  deleteBonus: (id: number) => `/bonuses/${id}`,

  // VaccinationTypes
  getVaccinationTypes: '/vaccination_types',

  // VaccinationForms
  getVaccinationForms: '/vaccination_forms',
  getVaccinationForm: (id: number) => `/vaccination_forms/${id}`,
  postVaccinationForm: `/vaccination_forms`,
  putVaccinationForm: (id: number) => `/vaccination_forms/${id}`,
  deleteVaccinationForm: (id: number) => `/vaccination_forms/${id}`,
  acknowledgeVaccinationForm: (id: number) =>
    `/vaccination_forms/${id}/acknowledge`,

  // Magic Link
  postMagicLink: `/magic_login_links`,

  // PendingVerificationPunchCards
  getPendingVerificationPunchCards: '/pending_verification_punch_cards',
  getPendingVerificationPunchCard: (id: number) =>
    `/pending_verification_punch_cards/${id}`,
  postPendingVerificationPunchCard: `/pending_verification_punch_cards`,
  putPendingVerificationPunchCard: (id: number) =>
    `/pending_verification_punch_cards/${id}`,
  deletePendingVerificationPunchCard: (id: number) =>
    `/pending_verification_punch_cards/${id}`,

  // Calendars
  getCalendars: '/calendars',
  getCalendar: (id: number) => `/calendars/${id}`,
  postCalendar: `/calendars`,
  putCalendar: (id: number) => `/calendars/${id}`,
  deleteCalendar: (id: number) => `/calendars/${id}`,

  // PayStubs
  getPayStubs: '/pay_data',
  getPayStub: (id: number) => `/pay_data/${id}`,

  // Shift Identifier Policies
  getShiftIdentifierPolicies: '/gig_identifier_policies',
  getShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,
  postShiftIdentifierPolicy: `/gig_identifier_policies`,
  putShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,
  deleteShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,

  // Groups
  getGroups: '/groups',
  getGroup: (id: number) => `/groups/${id}`,
  postGroup: `/groups`,
  putGroup: (id: number) => `/groups/${id}`,
  deleteGroup: (id: number) => `/groups/${id}`,

  // Group Applications
  getGroupApplications: `/groups/applications`,
  postGroupApplication: `/groups/applications`,
  postGroupApplicationAccept: '/groups/applications/accept',
  postGroupApplicationDecline: '/groups/applications/decline',
  putGroupApplication: '/groups/applications',

  // Group Invites
  getGroupInvites: '/group_invites',
  getGroupInvite: (id: number) => `/group_invites/${id}`,
  postGroupInvite: '/group_invites',
  putGroupInvite: (id: number) => `/group_invites/${id}`,

  // Group Members
  getGroupMembers: '/group_members',
  deleteGroupMember: (id: number) => `/group_members/${id}`,

  // Web Sockets Data
  getWebSocketData: '/websocket_data',

  // Suggested Group Names
  getSuggestedGroupNames: '/suggested_group_names',
  getSuggestedGroupName: (id: number) => `/suggested_group_names/${id}`,

  // Request Specific Upshifters
  getRequestApplicationsUsers: '/request/applications/users',
  getPreselectedRequestApplicationUsers:
    'request/applications/preselected_users',
  getRequestApplications: '/request/applications',
  postRequestApplication: `/request/applications`,
  deleteRequestApplication: (id: number) => `/request/applications/${id}`,

  // Ach Accounts
  postAchAccountInit: '/ach_accounts/init',
  postAchAccountCustomerDetails: '/ach_accounts/customer_details',
  postAchAccountBankDetails: '/ach_accounts/bank_details',
  postAchAccountConfirm: '/ach_accounts/confirm',
  getAchAccounts: '/ach_accounts',
  deleteAchAccount: (id: number) => `/ach_accounts/${id}`,

  // Block Properties
  getBlockProperties: '/block_properties',
  postBlockProperties: '/block_properties',
  getBlockProperty: (id: number) => `/block_properties/${id}`,
  putBlockProperty: (id: number) => `/block_properties/${id}`,
  deleteBlockProperties: '/block_properties',
  getBlockPropertiesUsers: '/block_properties/users',
}
