import { Dispatch } from 'redux'
import {
  GetShifts,
  GetShiftsErrors,
  ShiftsFetching,
  ClearShiftsReducer,
  ShiftsSetHasMore,
  ShiftsUpdateShiftApplicants,
  GetShiftsWithoutConcat,
} from './actions'
import { IGetShiftsThunkParams } from 'models/Shift'
import { API } from 'network'

export type ShiftsActions =
  | ReturnType<typeof GetShifts>
  | ReturnType<typeof GetShiftsWithoutConcat>
  | ReturnType<typeof GetShiftsErrors>
  | ReturnType<typeof ShiftsFetching>
  | ReturnType<typeof ClearShiftsReducer>
  | ReturnType<typeof ShiftsSetHasMore>
  | ReturnType<typeof ShiftsUpdateShiftApplicants>

export const getShifts = ({
  params,
  preventConcatenate,
}: IGetShiftsThunkParams) => async (dispatch: Dispatch<ShiftsActions>) => {
  if (!params?.page) {
    dispatch(ClearShiftsReducer())
    dispatch(ShiftsSetHasMore(true))
  }
  dispatch(ShiftsFetching(true))
  try {
    const response = await API.Shifts.getShifts(params)
    if (preventConcatenate) {
      dispatch(GetShiftsWithoutConcat(response))
    } else {
      dispatch(GetShifts(response))
    }
    return response
  } catch (error) {
    dispatch(GetShiftsErrors(error))
    return error
  } finally {
    dispatch(ShiftsFetching(false))
  }
}
